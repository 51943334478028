import styled, { css } from "styled-components";
import { query } from "../../style/mediaQueries";

const inputGlobalStyle = css`
  background-color: #212121;
  border: 0px;
  padding: 0.8rem;
  outline: none;
  margin: 0.5rem;
  color: #fff;
  font-family: arial;
`;

// Default Style
const defaultStyle = {
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  form: css`
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  `,
  input: css`
    ${inputGlobalStyle}
  `,
  textarea: css`
    ${inputGlobalStyle}
    height: 300px;
  `,
};

// Media Queries
const queries = {
  tablet: {
    container: query.tablet(css``),
    form: query.tablet(css`
      width: 500px;
    `),
    inputContainer: query.tablet(css``),
    input: query.tablet(css``),
    textarea: query.tablet(css``),
  },
  laptop: {
    container: query.laptop(css`
      display: flex;
      justify-content: space-around;
      align-items: center;
      min-height: calc(100vh - 50px);
    `),
    form: query.laptop(css`
      width: 450px;
      padding: 1rem;
      margin: 0.5rem;
      button {
        margin-left: auto;
      }
    `),
    inputContainer: query.laptop(css`
      display: flex;
    `),
    input: query.laptop(css`
      width: auto;
    `),
    textarea: query.laptop(css`
      width: auto;
      height: 300px;
      resize: none;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 0px !important;
      }
    `),
  },
};

const Container = styled.div`
  ${defaultStyle.container}
  ${queries.tablet.container}
  ${queries.laptop.container}
`;

const Form = styled.form`
  ${defaultStyle.form}
  ${queries.tablet.form}
  ${queries.laptop.form}
`;

const InputContainer = styled.div`
  ${defaultStyle.inputContainer}
  ${queries.tablet.inputContainer}
  ${queries.laptop.inputContainer}
`;

const Input = styled.input`
  ${defaultStyle.input}
  ${queries.tablet.input}
  ${queries.laptop.input}
`;

const TextArea = styled.textarea`
  ${defaultStyle.textarea}
  ${queries.tablet.textarea}
  ${queries.laptop.textarea}
`;

const Status = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  padding: 1rem;
  font-family: Arial, Helvetica, sans-serif;
  color: #fff;
  font-weight: bolder;
  display: none;
  ${({ error }) =>
    error
      ? css`
          background-color: red;
          display: block;
        `
      : css`
          background-color: green;
          display: block;
        `}
`;

const StyledContact = {
  Container,
  Form,
  InputContainer,
  Input,
  TextArea,
  Status,
};

export { StyledContact };
