import { useState } from "react";
import axios from "axios";

const useContact = () => {
  const [status, setStatus] = useState({
    error: false,
    success: false,
    message: "",
    isSubmit: false,
  });
  const [form, setForm] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const emailIsValid = !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
    form.email
  );

  const timer = () => {
    setTimeout(() => {
      setStatus({
        error: false,
        success: false,
        message: "",
        isSubmit: false,
      });
    }, [4000]);
  };

  const submit = async (e) => {
    e.preventDefault();
    if (!form.name || !form.email || !form.message) {
      setStatus({
        error: true,
        success: false,
        isSubmit: true,
        message: "Veuillez indiquer les champs manquants",
      });
      timer();
    } else if (
      form.name.length > 100 ||
      form.email.length > 150 ||
      form.message.length > 500
    ) {
      setStatus({
        error: true,
        success: false,
        isSubmit: true,
        message: "Champs trop longs",
      });
      timer();
    } else if (emailIsValid) {
      setStatus({
        error: true,
        success: false,
        isSubmit: true,
        message: "Veuillez entrer une adresse E-mail valide",
      });
      timer();
    } else {
      await axios
        .post("https://www.admin.horlogesdautrefois.fr/api/email", {
          name: form.name,
          email: form.email,
          message: form.message,
          subject: form.subject,
        })
        .then();
      setStatus({
        error: false,
        success: true,
        isSubmit: true,
        message: "Message envoyé !",
      });
      setForm({
        name: "",
        email: "",
        subject: "",
        message: "",
      });
      timer();
    }
  };
  return { form, setForm, status, submit };
};

export default useContact;
