import { Helmet } from "react-helmet-async";
import { Clock, Button, Title } from "../../components";
import { FadeIn } from "../../style/animation";
import { useContact } from "../../hooks";
import { StyledContact } from "./Contact.style";

const Contact = () => {
  const { form, setForm, submit, status } = useContact();

  const inputs = [
    { inputName: "name", type: "text", placeholder: "Votre nom" },
    { inputName: "email", type: "email", placeholder: "Votre Adresse email" },
    { inputName: "subject", type: "text", placeholder: "Le sujet" },
  ];

  return (
    <FadeIn>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <StyledContact.Container>
        <Title>Me Contacter</Title>
        <StyledContact.Form onSubmit={(e) => submit(e)}>
          {inputs.map(({ inputName, type, placeholder }) => (
            <StyledContact.Input
              placeholder={placeholder}
              type={type}
              onChange={(e) =>
                setForm({ ...form, [inputName]: e.target.value })
              }
              value={form[inputName]}
            />
          ))}
          <StyledContact.TextArea
            placeholder="Votre message"
            onChange={(e) => setForm({ ...form, message: e.target.value })}
            value={form.message}
          ></StyledContact.TextArea>
          <Button type="button">Envoyer</Button>
        </StyledContact.Form>
        {status.isSubmit && (
          <StyledContact.Status error={status.error}>
            {status.message}
          </StyledContact.Status>
        )}
      </StyledContact.Container>
    </FadeIn>
  );
};

export default Contact;
